import revive_payload_client_xTuIqUYj1C from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_3jMwjE8IpB from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_AYqHnF3j46 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_aNnyo809ij from "/opt/build/repo/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_vite@5.4.17_@types+node@20.17.30_sass@1.86.3_ter_b29068618593dad5cd8e59a9e0af2669/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_q4xJrcoqXa from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3PDZffcpYU from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_GhzX5Hmjl4 from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_izcte5U1rd from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_3bGoQgQqXa from "/opt/build/repo/node_modules/.pnpm/@pinia+nuxt@0.4.11_magicast@0.3.5_typescript@5.8.3_vue@3.5.13_typescript@5.8.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/build/repo/.nuxt/components.plugin.mjs";
import prefetch_client_UlgwcNp0ta from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_xatYBYQUiw from "/opt/build/repo/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_magicast@0.3.5_nuxt@3.12.4_@parcel+watcher@2.5.1_@type_d20551f2ad3f0da6812cd8bdea1403b2/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/opt/build/repo/.nuxt/pwa-icons-plugin.ts";
import pwa_client_xVg2eVO6p8 from "/opt/build/repo/node_modules/.pnpm/@vite-pwa+nuxt@0.9.1_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_vite@5.4.17_@types_74b6a36d13382cb7d623766a7667ba89/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import plugin_gd0WNyZjR3 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+apollo@5.0.0-alpha.8_magicast@0.3.5_rollup@4.39.0_typescript@5.8.3_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/apollo/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_N9kgsyIUjr from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_ZnFngsQDx1 from "/opt/build/repo/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.39.0_vue@3.5.13_typescript@5.8.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import animation_directives_client_BcJ9PYvc9H from "/opt/build/repo/plugins/animation-directives.client.js";
import apollo_IRWTEOPleP from "/opt/build/repo/plugins/apollo.js";
import sentry_client_GoGQuZo4Et from "/opt/build/repo/plugins/sentry.client.js";
import setup_directives_client_fwoBYyxQ4Q from "/opt/build/repo/plugins/setup-directives.client.js";
import tracking_client_fEUopUxxSj from "/opt/build/repo/plugins/tracking.client.js";
export default [
  revive_payload_client_xTuIqUYj1C,
  unhead_3jMwjE8IpB,
  router_AYqHnF3j46,
  _0_siteConfig_aNnyo809ij,
  payload_client_q4xJrcoqXa,
  navigation_repaint_client_3PDZffcpYU,
  check_outdated_build_client_GhzX5Hmjl4,
  chunk_reload_client_izcte5U1rd,
  plugin_vue3_3bGoQgQqXa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_UlgwcNp0ta,
  plugin_xatYBYQUiw,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_xVg2eVO6p8,
  plugin_gd0WNyZjR3,
  switch_locale_path_ssr_N9kgsyIUjr,
  i18n_ZnFngsQDx1,
  animation_directives_client_BcJ9PYvc9H,
  apollo_IRWTEOPleP,
  sentry_client_GoGQuZo4Et,
  setup_directives_client_fwoBYyxQ4Q,
  tracking_client_fEUopUxxSj
]