import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/pages/runtime/validate.js";
import netlify_45redirects_45global from "/opt/build/repo/middleware/netlify-redirects.global.ts";
import query_45string_45campaign_45global from "/opt/build/repo/middleware/query-string-campaign.global.ts";
import redirect_45to_45it_45global from "/opt/build/repo/middleware/redirect-to-it.global.ts";
import remove_45trailing_45slash_45global from "/opt/build/repo/middleware/remove-trailing-slash.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.5.1_@types+node@20.17.30_db0@0.3.1_eslint@8.57.1_ioredis@_094bb6704935a3f77e02728099e46be9/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  netlify_45redirects_45global,
  query_45string_45campaign_45global,
  redirect_45to_45it_45global,
  remove_45trailing_45slash_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-showcase": () => import("/opt/build/repo/middleware/redirect-showcase.ts")
}